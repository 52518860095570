import axios from "axios";
export const website = "https://shopbackend.cogdevelopers.com";
export const webApi = `${website}/api`;
export const webSse = `${website}/sse`;

export const getAuth = (url) => {
  return fetch(`${webApi}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  });
};

export const postAuth = async (url, data) => {
  const response = await fetch(`${webApi}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
    body: data,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Error with post request");
  }

  return response.json();
};

export const post = (url, data) => {
  return axios.post(`${webApi}${url}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    withCredentials: true,
  });
};

export const get = async (url) => {
  return await fetch(`${webApi}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  });
};

export const putAuth = (url, data) => {
  return fetch(`${webApi}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
    credentials: "include",
  });
};

export const deleteAuth = (url) => {
  return fetch(`${webApi}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  });
};

export const LogoutAuth = async (url) => {
  const response = await fetch(`${webApi}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Error with post request");
  }

  return response.json();
};
